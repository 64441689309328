/* Style for the container of the logos */
.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* Style for individual logos */
  .logo {
    height: 50px;
    width: auto; /* This ensures the aspect ratio is maintained */
  }
  