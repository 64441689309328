.links-section {
    text-align: center;
}
  
.links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 6px;
    align-self: stretch;
    flex-grow: 0;
}

.links-container .link-item {
    display: inline-block;
}
  
.links-section a {
    display: block; /* Make the link a block element to control height */
    font-weight: 400;
    font-size: 11px;
    text-decoration-line: underline;
    color: #000000;
}